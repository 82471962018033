import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
Vue.use(VueRouter)

const routes = [
  {
    path: '/', // 首页
    component: () => import('@/views/indexHome')
  }, {
    path: '/erroff', // 关闭
    component: () => import('@/views/err/errOff')
  }, {
    path: '/err404', // 无授权
    component: () => import('@/views/err/errNull')
  }, {
    path: '/test', // 测试
    component: () => import('@/views/testYanxuan')
  }, {
    path: '/listinfo', // 信息列表
    component: () => import('@/views/info/listInfo')
  }, {
    path: '/listinfo/:id', // 信息列表
    component: () => import('@/views/info/listInfo')
  }, {
    path: '/listinfo/:id/:page', // 信息列表
    component: () => import('@/views/info/listInfo')
  }, {
    path: '/showinfo/:id', // 信息明细页
    component: () => import('@/views/info/showInfo')
  },
  /// ///////////////////////////
  {
    path: '/listpro', // 产品列表
    component: () => import('@/views/pro/listPro')
  }, {
    path: '/listpro/:id', // 产品列表
    component: () => import('@/views/pro/listPro')
  }, {
    path: '/listpro/:id/:page', // 产品列表
    component: () => import('@/views/pro/listPro')
  }, {
    path: '/showpro/:id', // 产品明细页面
    component: () => import('@/views/pro/showPro')
  },
  {
    path: '/listshop/', // 卖家列表
    component: () => import('@/views/pro/listShop')
  },
  {
    path: '/showshop/:id', // 卖家明细（主页）
    component: () => import('@/views/pro/showShop')
  },
  /// ///////////////////////////
  {
    path: '/docfile/:id', // 文件文档
    component: () => import('@/views/files/docFile')
  }, {
    path: '/article/:id', // 文章
    component: () => import('@/views/article/docArticle')
  },
  {
    path: '/vip', // 收费标准
    component: () => import('@/views/appVip')
  }, {
    path: '/tel', // 电话号码页面
    component: () => import('@/views/info/telInfo')
  }, {
    path: '/adminlogin', // 管理员登陆
    component: () => import('@/views/admin/adminLogin')
  }, {
    path: '/admin', // 管理员总后台
    component: () => import('@/views/admin/adminCenter'),
    redirect: '/admininfolist',
    children: [
      {
        path: '/admininfolist', // 总后台信息列表
        component: () => import('@/views/admin/info/infoList')
      }, {
        path: '/adminmember', // 总后台人员列表
        component: () => import('@/views/admin/member/memberList')
      }, {
        path: '/adminmoney', // 总后台充值交易
        component: () => import('@/views/admin/adminMoney')
      }, {
        path: '/adminarticle', // 总后台文章管理
        component: () => import('@/views/admin/article/articleList.vue')
      }, {
        path: '/adminfile', // 总后台文件管理
        component: () => import('@/views/admin/file/fileList.vue')
      }, {
        path: '/adminmodify', // 总后台系统设置
        component: () => import('@/views/admin/adminModify')
      }
    ]
  }, {
    path: '/usercenter', // 个人管理中心
    component: () => import('@/views/member/userCenter'),
    redirect: '/userinfos',
    children: [
      {
        path: '/userinfos', // 个人用户发布信息
        component: () => import('@/views/member/info/userInfos')
      }, {
        path: '/userpay', // 个人用户缴费
        component: () => import('@/views/member/pay/userPay')
      }, {
        path: '/usermodify', // 个人用户个人资料
        component: () => import('@/views/member/userModify')
      }
    ]
  },
  {
    path: '/login', // 个人登陆
    component: () => import('@/views/member/userLogin')
  }, {
    path: '/reg', // 个人注册
    component: () => import('@/views/member/userReg')
  }, {
    path: '/pass', // 个人修改密码
    component: () => import('@/views/member/userPass')
  }, {
    path: '/join', // 卖家注册（入驻）
    component: () => import('@/views/seller/sellerReg')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

// 解决同一路由加载两遍报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

// 路由导航卫士
router.beforeEach((to, from, next) => {
  // if (to.path == '/login') {
  //   return next()
  // }
  // const token = window.sessionStorage.getItem('token')
  // if (!token) {
  //   return next({ path: '/login' })
  // }
  // next()
  const statData = {
    url: to.path,
    domain: window.location.host
  }
  axios.post('//stat.hzfc.5n5.cn/vue', statData).then(res => {

  })

  next()
})
